<template>
  <LandingPageLayout>
    <template>
      <CourseSlider
        :isOnCourse="true"
        :showBtn="false"
        v-if="!$route.query.search"
      />
      <DownloadApp :isOnCourse="true" />
      <section class="courseware-section" id="courseware-section">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="course-title">
                {{ $t("course.explore_all_courses") }}
              </div>
            </div>
          </div>
          <div class="row filter-row">
            <div class="col-12 col-md-7 col-lg-6 row-inner row-left">
              <div class="course__filter--container">
                <div
                  :class="[
                    'filter-btn btn-inner',
                    {
                      focused: isFilterFocused,
                      pressed: isFilterPressed,
                      active: isFilterPanelOpen || filterCount > 0
                    }
                  ]"
                  @click="toggleFilterPanel"
                  @focus="isFilterFocused = true"
                  @blur="isFilterFocused = false"
                  @mousedown="isFilterPressed = true"
                  @mouseup="isFilterPressed = false"
                  @touchstart="isFilterPressed = true"
                  @touchend="isFilterPressed = false"
                  tabindex="0"
                >
                  <img :src="filterIconUrl" alt="filter" />
                  <span class="btn-label">
                    {{ $t("course.filter.title") }}
                    <span class="filter-count" v-if="filterCount > 0">
                      ({{ filterCount }})
                    </span>
                  </span>
                  <button
                    type="button"
                    class="remove-filter"
                    @click.stop="removeFilters"
                    v-if="filterCount > 0"
                  >
                    <img :src="removeWhiteIcon" alt="remove" />
                  </button>
                </div>
                <!-- TEMPORARILY HIDING SORT BY FOR SOFT LAUNCH AS LOGIC IS NOT WORKING AS EXPECTED FROM BACKEND -->
                <!-- <div
                  :class="[
                    'sort-btn btn-inner',
                    {
                      focused: isSortFocused,
                      pressed: isSortPressed,
                      active: dropdownValue?.label
                    }
                  ]"
                  id="select-category"
                  @click="toggleDropdown"
                  @focus="isSortFocused = true"
                  @blur="isSortFocused = false"
                  @mousedown="isSortPressed = true"
                  @mouseup="isSortPressed = false"
                  @touchstart="isSortPressed = true"
                  @touchend="isSortPressed = false"
                  tabindex="0"
                >
                  <img :src="sortIconUrl" alt="sort" />
                  <span class="btn-label">
                    {{ $t("course.sort_by.title") }}
                  </span>
                  <span class="selected-label d-none d-md-block">
                    {{ dropdownValue?.label }}
                  </span>
                  <img :src="arrowIconUrl" alt="arrow" class="arrow-icon" />
                  <ul class="select__item--inner" v-if="isDropdownOpen">
                    <li
                      v-for="(item, index) in sortTypes"
                      :key="index"
                      @click="selectDropdownItem(item)"
                      class="clickable-category"
                      :class="{ current: item.value === dropdownValue?.value }"
                    >
                      <span>{{ item.label }}</span>
                      <img
                        :src="checkIcon"
                        alt="tick"
                        v-if="item.value === dropdownValue?.value"
                      />
                    </li>
                  </ul>
                </div> -->
              </div>
            </div>
            <div class="col-12 col-md-5 col-lg-6 row-inner row-right">
              <div class="course-count" v-if="coursewareList?.count">
                {{
                  $t("search_course.results_found", {
                    count: coursewareList?.count
                  })
                }}
              </div>
            </div>
          </div>
          <div class="row course-row">
            <div class="col-12 col-lg-3 left-panel" v-if="isFilterPanelOpen">
              <div class="filter__list--container">
                <div class="filter__list--inner">
                  <div
                    class="list-inner count"
                    v-if="
                      !isDesktop && isFilterPanelOpen && coursewareList?.count
                    "
                  >
                    {{ coursewareList?.count }} {{ $t("course.results") }}
                  </div>
                  <div class="list-inner">
                    <div
                      class="list-heading"
                      @click="showFilterItems('pacing')"
                    >
                      {{ $t("course.filter.pacing") }}
                      <span class="arrow" v-if="!isDesktop">
                        <img
                          :src="arrowIcon"
                          alt="arrow"
                          :style="
                            isPacingItemsVisible
                              ? 'transform: rotate(180deg);'
                              : ''
                          "
                        />
                      </span>
                    </div>
                    <div class="list" v-if="isPacingItemsVisible">
                      <div
                        class="list-item"
                        v-for="item in filterTypes"
                        :key="item.value"
                      >
                        <input
                          type="radio"
                          v-model="selectedFilterType"
                          :id="item.value"
                          name="course_types"
                          :value="item.value"
                          @click="setFilter"
                          :checked="selectedFilterType === item.value"
                        />
                        <label :for="item.value">
                          {{ item.label }}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="list-inner">
                    <div
                      class="list-heading"
                      @click="showFilterItems('language')"
                    >
                      {{ $t("my_profile.language.heading") }}
                      <span class="arrow" v-if="!isDesktop">
                        <img
                          :src="arrowIcon"
                          alt="arrow"
                          :style="
                            isLanguageItemsVisible
                              ? 'transform: rotate(180deg);'
                              : ''
                          "
                        />
                      </span>
                    </div>
                    <div class="list" v-if="isLanguageItemsVisible">
                      <div
                        class="list-item"
                        v-for="item in displayedLanguages"
                        :key="item.id"
                      >
                        <input
                          type="radio"
                          v-model="selectedLanguage"
                          :id="item.id"
                          name="languages"
                          :value="item.id"
                          @click="setLanguage"
                          :checked="selectedLanguage === item.value"
                        />
                        <label :for="item.id">
                          {{ item.displayName }}
                        </label>
                      </div>
                    </div>
                    <button
                      type="button"
                      class="show-more"
                      @click="showMoreFilters"
                      v-if="!showAll && isLanguageItemsVisible"
                    >
                      {{ $t("course.filter.show_more") }}
                      <img :src="arrowBlueIcon" alt="arrow" />
                    </button>
                  </div>
                </div>
                <div
                  class="filter__list--inner"
                  v-if="$route.query.search && isDesktop"
                >
                  <div class="list-inner">
                    <div class="list-heading">
                      {{ $t("course.top_courses") }}
                    </div>
                    <div class="list no-padding">
                      <div
                        class="list-item top__courses--container"
                        v-for="course in coursewareList?.semantic_results"
                        :key="course.id"
                      >
                        <div
                          class="top__courses--inner"
                          @click="onCardClick(course)"
                        >
                          <div class="course-image">
                            <img
                              :src="
                                course.image_url
                                  ? course.image_url
                                  : placeholderImage
                              "
                              alt="course"
                              @error="handleImageError"
                            />
                          </div>
                          <div class="course-title">
                            {{ course?.title }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="done-btn" v-if="!isDesktop && isFilterPanelOpen">
                  <LxpButton
                    variant="primary"
                    type="button"
                    @click="hideFilterPanel"
                  >
                    {{ $t("general.done") }}
                  </LxpButton>
                </div>
              </div>
            </div>
            <div
              :class="['col-12', { 'col-lg-9 right-panel': isFilterPanelOpen }]"
            >
              <LxpLoader class="response-spinner" v-if="isCourseLoading" />
              <div
                :class="[
                  'course__list--container',
                  { 'full-width': !isFilterPanelOpen }
                ]"
                v-else-if="
                  coursewareList && coursewareList?.results?.length > 0
                "
              >
                <CourseCard
                  @cardClick="onCardClick"
                  v-for="course in coursewareList?.results"
                  :course="course"
                  :key="course.id"
                />
              </div>
              <EmptyState
                :imageIcon="imageIcon"
                :title="$t('course.empty.title')"
                :content="$t('course.empty.content')"
                v-else-if="
                  coursewareList && coursewareList?.results?.length === 0
                "
              />
            </div>
          </div>
          <div
            class="row"
            v-if="coursewareList && coursewareList?.results?.length > 0"
          >
            <div class="col-12 d-flex justify-content-end">
              <b-pagination
                v-model="currentPage"
                :current-page="currentPage"
                :per-page="perPage"
                first-number
                last-number
                :total-rows="coursewareList?.count || 0"
                @page-click="handlePaginationClick"
              ></b-pagination>
            </div>
          </div>
        </div>
        <div
          class="filter-overlay"
          v-if="!this.isDesktop && isFilterPanelOpen"
          @click="preventClick"
        >
          <div class="close-btn" @click="hideFilterPanel">
            <img :src="removeIcon" alt="close" />
          </div>
        </div>
      </section>
      <StayTuned />
    </template>
  </LandingPageLayout>
</template>

<script>
import LandingPageLayout from "../components/Layout/LandingPageLayout.vue";
import CourseSlider from "@/components/Landing/CourseSlider.vue";
import DownloadApp from "@/components/Landing/DownloadApp.vue";
import CourseCard from "@/components/Landing/CourseCard.vue";
import StayTuned from "@/components/Landing/StayTuned.vue";
import EmptyState from "./EmptyState.vue";
import userLanguageMixin from "@/core/mixins/userLanguage";
import { LxpButton, LxpLoader } from "didactica";
export default {
  mixins: [userLanguageMixin],
  components: {
    LandingPageLayout,
    CourseSlider,
    DownloadApp,
    CourseCard,
    StayTuned,
    EmptyState,
    LxpButton,
    LxpLoader
  },
  data() {
    return {
      filterIcon: require("@/assets/images/landing/course/filter.svg"),
      filterWhiteIcon: require("@/assets/images/landing/course/filter-white.svg"),
      sortIcon: require("@/assets/images/landing/course/sort.svg"),
      sortWhiteIcon: require("@/assets/images/landing/course/sort-white.svg"),
      arrowIcon: require("@/assets/images/landing/course/arrow.svg"),
      arrowWhiteIcon: require("@/assets/images/landing/course/arrow-white.svg"),
      arrowBlueIcon: require("@/assets/images/landing/course/arrow-blue.svg"),
      checkIcon: require("@/assets/images/landing/course/tick.svg"),
      placeholderImage: require("@/assets/images/not-found/course-img-not-found.svg"),
      removeIcon: require("@/assets/images/vle/close-icon.svg"),
      removeWhiteIcon: require("@/assets/images/landing/course/remove-white.svg"),
      imageIcon: require("@/assets/images/landing/search/empty-search.svg"),
      isFilterFocused: false,
      isFilterPressed: false,
      isSortFocused: false,
      isSortPressed: false,
      coursewareList: null,
      filterTypes: [
        {
          label: this.$t("data.pacing.self"),
          value: "self"
        },
        {
          label: this.$t("data.pacing.instructor"),
          value: "instructor"
        }
      ],
      sortTypes: [
        {
          label: this.$t("course.sort_by.newest"),
          value: "new"
        },
        {
          label: this.$t("course.sort_by.oldest"),
          value: "old"
        }
      ],
      languageList: [],
      searchText: "",
      selectedFilterType: "",
      selectedLanguage: "",
      dropdownValue: {
        label: this.$t("course.sort_by.newest"),
        value: "new"
      },
      isDropdownOpen: false,
      isFilterPanelOpen: false,
      currentPage: 1,
      isDesktop: false,
      isTab: false,
      isMobile: false,
      showAll: false,
      isPacingItemsVisible: true,
      isLanguageItemsVisible: true,
      isCourseLoading: false
    };
  },
  computed: {
    perPage() {
      let perPageNumber;
      if (this.isDesktop) {
        if (this.isFilterPanelOpen) {
          perPageNumber = 15;
        } else {
          perPageNumber = 25;
        }
      } else if (this.isTab) {
        perPageNumber = 21;
      } else if (this.isMobile) {
        perPageNumber = 9;
      }
      return perPageNumber;
    },
    filterIconUrl() {
      if (
        this.isFilterPressed ||
        this.isFilterPanelOpen ||
        this.filterCount > 0
      ) {
        return this.filterWhiteIcon || this.filterIcon;
      } else {
        return this.filterIcon;
      }
    },
    sortIconUrl() {
      if (this.isSortPressed) {
        return this.sortWhiteIcon || this.sortIcon;
      } else {
        return this.sortIcon;
      }
    },
    arrowIconUrl() {
      if (this.isSortPressed) {
        return this.arrowWhiteIcon || this.arrowIcon;
      } else {
        return this.arrowIcon;
      }
    },
    displayedLanguages() {
      if (this.showAll) {
        return this.languageList;
      }
      return this.languageList.slice(0, 6);
    },
    filterCount() {
      const hasFilterType = !!this.selectedFilterType;
      const hasLanguage = !!this.selectedLanguage;

      if (hasFilterType && hasLanguage) {
        return 2;
      } else if (hasFilterType || hasLanguage) {
        return 1;
      } else {
        return 0;
      }
    }
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize);
    if (this.$route.query.search) {
      this.searchText = this.$route.query.search;
    }
    this.fetchCoursewareList();
    this.languageList = this.getAllLanguage();
    document.getElementById("app").scrollIntoView();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    fetchCoursewareList() {
      this.isCourseLoading = true;
      let params = {
        pageNumber: this.currentPage,
        pageSize: this.perPage ? this.perPage : 25,
        semanticSize: 4,
        pacing: this.selectedFilterType,
        // sort_by: this.dropdownValue?.value,
        searchText: this.searchText ? this.searchText : "",
        language: this.selectedLanguage ? this.selectedLanguage : "all"
      };
      this.$store
        .dispatch("getCoursewareList", params)
        .then(response => {
          this.coursewareList = response;
          this.isCourseLoading = false;
        })
        .catch(() => {
          this.coursewareList = null;
          this.isCourseLoading = false;
        });
    },
    setFilter(e) {
      this.selectedFilterType = e.target.value;
      this.fetchCoursewareList();
    },
    setLanguage(e) {
      this.selectedLanguage = e.target.value;
      this.fetchCoursewareList();
    },
    selectDropdownItem(item) {
      this.dropdownValue = item;
      this.isDropdownOpen = false;
      this.fetchCoursewareList();
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
      if (this.isDropdownOpen) {
        document.addEventListener("click", this.outsideClickHandler);
      } else {
        document.removeEventListener("click", this.outsideClickHandler);
      }
    },
    outsideClickHandler(event) {
      const dropdown = document.getElementById("select-category");
      if (!dropdown.contains(event.target)) {
        this.isDropdownOpen = false;
        document.removeEventListener("click", this.outsideClickHandler);
      }
    },
    onCardClick(course) {
      this.$router.push({
        name: "Courses Detail",
        params: { id: course.id },
        query: { source: course.source }
      });
    },
    toggleFilterPanel() {
      this.isFilterPanelOpen = !this.isFilterPanelOpen;
      if (
        this.isFilterPanelOpen &&
        !this.isDesktop &&
        this.$route.name === "Courses"
      ) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
      this.fetchCoursewareList();
    },
    onResize() {
      this.isDesktop = window.innerWidth > 991;
      this.isTab = window.innerWidth > 767 && window.innerWidth < 992;
      this.isMobile = window.innerWidth < 768;
    },
    preventClick(event) {
      event.stopPropagation();
    },
    hideFilterPanel() {
      this.isFilterPanelOpen = false;
      if (!this.isDesktop && this.$route.name === "Courses") {
        document.body.style.overflow = "auto";
      }
    },
    showMoreFilters() {
      this.showAll = true;
    },
    handleImageError(event) {
      event.target.src = this.placeholderImage;
    },
    removeFilters() {
      this.selectedFilterType = "";
      this.selectedLanguage = "";
      this.fetchCoursewareList();
    },
    showFilterItems(value) {
      if (!this.isDesktop) {
        if (value === "pacing") {
          this.isPacingItemsVisible = !this.isPacingItemsVisible;
        } else if (value === "language") {
          this.isLanguageItemsVisible = !this.isLanguageItemsVisible;
        }
      }
    },
    handlePaginationClick() {
      this.$nextTick(() => {
        const section = document.getElementById("courseware-section");
        if (section) {
          const offset = document.getElementById("lp-header")?.offsetHeight;
          const sectionTop =
            section.getBoundingClientRect().top + window.scrollY - offset;
          window.scrollTo({ top: sectionTop });
        }
      });
    }
  },
  watch: {
    currentPage() {
      this.fetchCoursewareList();
    }
  }
};
</script>
<style lang="scss" scoped>
.courseware-section {
  background-color: $brand-primary-50;
  padding: 48px 0px 88px;
  font-family: $font-family;
  .course-title {
    @include h3;
    color: $brand-neutral-900;
  }
  .filter-row {
    margin-top: 32px;
    .row-inner {
      @include flex-center;
      &.row-right {
        justify-content: flex-end;
      }
    }
    .course__filter--container {
      @include flex-center;
      width: 100%;
      .btn-inner {
        @include flex-center;
        padding: 7px 8px;
        border-radius: 16px;
        border: 1px solid transparent;
        background-color: $brand-neutral-0;
        box-shadow: 0px 1px 3px 1px rgba(33, 33, 33, 0.25);
        position: relative;
        max-height: 40px;
        cursor: pointer;
        .btn-label {
          @include label-large($brand-neutral-900, 500);
          margin-left: 8px;
          .filter-count {
            margin-left: 4px;
          }
        }
        .selected-label {
          @include label-large($brand-neutral-300, 400);
          letter-spacing: 0.25px;
          margin-left: 4px;
        }
        .arrow-icon {
          margin-left: 4px;
        }
        &:not(:first-child) {
          margin-left: 24px;
        }
        &:hover {
          background-color: $brand-primary-75;
        }
        &.focused {
          border-color: $brand-primary;
          background-color: $brand-primary-75;
          outline: none;
          box-shadow: none;
        }
        &.pressed {
          background-color: $brand-primary;
          outline: none;
          box-shadow: none;
          .btn-label {
            color: $brand-neutral-0;
          }
        }
        .select__item--inner {
          position: absolute;
          left: 0;
          top: calc(100% + 8px);
          width: 100%;
          border-radius: 16px;
          background-color: $brand-neutral-0;
          box-shadow: 0px 4px 8px 4px rgba(33, 33, 33, 0.2);
          padding: 12px;
          z-index: 1;
          li {
            @include body-medium;
            @include horizontal-space-between;
            color: $brand-neutral-900;
            padding: 4px;
            border-radius: 4px;
            cursor: pointer;
            &:hover {
              background-color: $brand-primary-75;
            }
          }
        }
      }
      .filter-btn {
        .remove-filter {
          border: none;
          background-color: transparent;
          padding: 0;
          margin: 0 0 0 8px;
          &:hover {
            border: none;
            outline: none;
          }
        }
        &.active {
          background-color: $brand-primary;
          .btn-label {
            color: $brand-neutral-0;
          }
        }
      }
    }
    .course-count {
      @include label-large($brand-public-disabled, 500);
    }
  }
  .course-row {
    margin-top: 24px;
    .filter__list--container {
      .filter__list--inner {
        width: 100%;
        border-radius: 16px;
        background-color: $brand-neutral-0;
        box-shadow: 0px 1px 3px 1px rgba(33, 33, 33, 0.25);
        padding: 16px;
        text-align: left;
        position: relative;
        .list-inner {
          &:not(:first-child) {
            margin-top: 20px;
          }
          &.count {
            @include subtitle-regular;
            color: $brand-neutral-700;
          }
          .list-heading {
            @include body-regular($brand-neutral-900, 700);
            @include horizontal-space-between;
          }
          .list {
            margin-top: 20px;
            @include flex-column-start;
            &:not(.no-padding) {
              padding-left: 4px;
            }
            .list-item {
              width: 100%;
              input {
                cursor: pointer;
                accent-color: $brand-primary-400;
              }
              label {
                cursor: pointer;
                margin: 0px 0px 0px 16px;
                @include body-regular($brand-neutral-900, 400);
                .count {
                  color: $brand-neutral-300;
                }
              }
              &:not(:first-child) {
                margin-top: 20px;
              }
            }
          }
          .show-more {
            margin: 12px 0px 0px;
            padding: 0;
            @include flex-center;
            @include body-regular($brand-primary-400);
            border: none;
            background-color: transparent;
            &:focus {
              border: none;
              outline: none;
            }
            img {
              margin-left: 8px;
            }
          }
        }
        &:not(:first-child) {
          margin-top: 24px;
        }
      }
      .done-btn {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 8px 16px;
        background-color: $brand-neutral-0;
        box-shadow: 0px -1px 3px 1px rgba(33, 33, 33, 0.25);
        @include flex-horizontal-center;
        z-index: 1;
        .btn {
          width: 100%;
          height: 54px;
        }
      }
    }
    .course__list--container {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      &:not(.full-width) {
        .course__card--container {
          width: 30.66%;
          &:not(:nth-child(-n + 3)) {
            margin-top: 24px;
          }
          &:not(:nth-child(3n + 1)) {
            margin-left: 4%;
          }
        }
      }
    }
  }
  .top__courses--container {
    .top__courses--inner {
      width: 100%;
      display: flex;
      align-items: flex-start;
      cursor: pointer;
      .course-image {
        border-radius: 16px;
        width: 65px;
        min-width: 65px;
        height: 69px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
      .course-title {
        @include line-clamp(2);
        @include body-regular($brand-neutral-900);
        margin-left: 8px;
        word-break: break-word;
      }
    }
    &:not(:first-child) {
      padding-top: 18px;
      margin-top: 18px;
      border-top: 1px solid $brand-neutral-200;
    }
  }
  .filter-overlay {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: calc(100% - 343px);
    height: 100%;
    background-color: rgba(45, 45, 45, 0.25);
    backdrop-filter: blur(4px);
    pointer-events: auto;
    z-index: 999;
    .close-btn {
      width: 40px;
      min-width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: $brand-primary-75;
      @include flex-horizontal-center;
      position: absolute;
      top: 15px;
      right: 15px;
    }
  }
  @media only screen and (min-width: 768px) {
    > .container {
      max-width: 710px;
    }
  }
  @media only screen and (min-width: 992px) {
    > .container {
      max-width: 1078px;
    }
    .course-row {
      .course__list--container {
        &.full-width {
          .course__card--container {
            width: 18.4%;
            &:not(:nth-child(-n + 5)) {
              margin-top: 24px;
            }
            &:not(:nth-child(5n + 1)) {
              margin-left: 2%;
            }
          }
        }
      }
    }
  }
  @media only screen and (min-width: 1200px) {
    .course-row {
      .left-panel {
        flex: 0 0 323px;
        max-width: 323px;
      }
      .right-panel {
        flex: 0 0 753px;
        max-width: 753px;
      }
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .course-row {
      .course__list--container {
        &.full-width {
          .course__card--container {
            width: 30.66%;
            &:not(:nth-child(-n + 3)) {
              margin-top: 24px;
            }
            &:not(:nth-child(3n + 1)) {
              margin-left: 4%;
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 991px) {
    .course-row {
      .filter__list--container {
        position: fixed;
        right: 0;
        top: 0;
        width: 343px;
        height: 100%;
        z-index: 99;
        background-color: $brand-neutral-0;
        box-shadow: 0px 1px 3px 1px rgba(33, 33, 33, 0.25);
        .filter__list--inner {
          border-radius: 0;
          padding: 0;
          box-shadow: none;
          height: calc(100% - 70px);
          overflow-y: auto;
          overflow-x: hidden;
          &::-webkit-scrollbar-track {
            border-radius: 8px;
            border: none;
            background-color: $brand-neutral-75;
          }

          &::-webkit-scrollbar {
            width: 4px;
            background-color: transparent;
          }

          &::-webkit-scrollbar-thumb {
            border-radius: 8px;
            border: none;
            background-color: $brand-neutral-300;
          }
          .list-inner {
            padding: 16px;
            margin-top: 0 !important;
            position: relative;
            &:not(:first-child) {
              &:before {
                content: "";
                position: absolute;
                left: 16px;
                top: 0;
                width: calc(100% - 32px);
                height: 1px;
                background-color: $brand-neutral-200;
                display: block;
              }
            }
          }
          &:not(:first-child) {
            margin-top: 0;
            position: relative;
            &:before {
              content: "";
              position: absolute;
              left: 16px;
              top: 0;
              width: calc(100% - 32px);
              height: 1px;
              background-color: $brand-neutral-200;
              display: block;
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 767px) {
    > .container {
      max-width: 373px;
    }
    .course-title {
      @include h5($brand-neutral-900);
      letter-spacing: 0.07px;
    }
    .filter-row {
      .course__filter--container {
        .btn-inner {
          width: 48%;
          justify-content: center;
          &:not(:first-child) {
            margin-left: 4%;
          }
        }
      }
      .row-inner {
        &.row-right {
          justify-content: flex-start;
          margin-top: 24px;
        }
      }
    }
    .course-row {
      .filter__list--container {
        width: 309px;
      }
    }
    .filter-overlay {
      width: calc(100% - 309px);
    }
  }
  @media only screen and (max-width: 400px) {
    .course-row {
      .filter__list--container {
        width: 80%;
      }
    }
    .filter-overlay {
      width: 20%;
    }
  }
}
</style>
<style lang="scss">
.courseware-section {
  .pagination {
    margin-top: 24px;
    margin-bottom: 0;
    padding: 8px;
    border-radius: 100px;
    background-color: $brand-primary-75;
    overflow: hidden;
    font-family: $font-family;
    @include flex-center;
    li {
      @include flex-center;
      &:first-child {
        .page-link {
          border-top-left-radius: 100px;
          border-bottom-left-radius: 100px;
        }
      }
      &:last-child {
        .page-link {
          border-top-right-radius: 100px;
          border-bottom-right-radius: 100px;
        }
      }
      &.active {
        .page-link {
          color: $brand-primary-400;
        }
      }
      &.disabled:not(.bv-d-xs-down-none) {
        display: none;
      }
      span.page-link,
      button.page-link {
        background-color: transparent;
        border: none;
        padding-top: 1px;
        padding-bottom: 1px;
        color: $brand-primary;
        @include flex-center;
      }
      span.page-link {
        font-size: 20px;
        line-height: 20px;
        height: 20px;
      }
      button.page-link {
        font-size: 11px;
        font-weight: 500;
        line-height: 16.5px;
        letter-spacing: 0.5px;
        padding-top: 3px;
        &:focus {
          box-shadow: none;
          border: none;
        }
      }
    }
  }
  @media only screen and (max-width: 767px) {
    .course-row {
      .course__list--container {
        &.full-width {
          .course__card--container {
            width: 100%;
            flex-direction: row;
            padding: 8px;
            &:not(:first-child) {
              margin-top: 24px;
            }
            .course-image {
              width: 94px;
              height: 107px;
              border-radius: 16px;
            }
            .course__card--inner {
              padding: 0;
              margin-left: 8px;
              background-color: transparent;
              .course-title {
                margin: 0;
                @include body-medium;
                min-height: 63px;
              }
              .course-footer {
                margin-top: 8px;
                .course-pacing {
                  order: -1;
                }
              }
            }
          }
        }
      }
    }
  }
}
.page-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $brand-neutral-0;
  z-index: 99;
  @include flex-horizontal-center;
}
</style>
