var render = function render(){var _vm=this,_c=_vm._self._c;return _c('LandingPageLayout',[[(!_vm.$route.query.search)?_c('CourseSlider',{attrs:{"isOnCourse":true,"showBtn":false}}):_vm._e(),_c('DownloadApp',{attrs:{"isOnCourse":true}}),_c('section',{staticClass:"courseware-section",attrs:{"id":"courseware-section"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"course-title"},[_vm._v(" "+_vm._s(_vm.$t("course.explore_all_courses"))+" ")])])]),_c('div',{staticClass:"row filter-row"},[_c('div',{staticClass:"col-12 col-md-7 col-lg-6 row-inner row-left"},[_c('div',{staticClass:"course__filter--container"},[_c('div',{class:[
                  'filter-btn btn-inner',
                  {
                    focused: _vm.isFilterFocused,
                    pressed: _vm.isFilterPressed,
                    active: _vm.isFilterPanelOpen || _vm.filterCount > 0
                  }
                ],attrs:{"tabindex":"0"},on:{"click":_vm.toggleFilterPanel,"focus":function($event){_vm.isFilterFocused = true},"blur":function($event){_vm.isFilterFocused = false},"mousedown":function($event){_vm.isFilterPressed = true},"mouseup":function($event){_vm.isFilterPressed = false},"touchstart":function($event){_vm.isFilterPressed = true},"touchend":function($event){_vm.isFilterPressed = false}}},[_c('img',{attrs:{"src":_vm.filterIconUrl,"alt":"filter"}}),_c('span',{staticClass:"btn-label"},[_vm._v(" "+_vm._s(_vm.$t("course.filter.title"))+" "),(_vm.filterCount > 0)?_c('span',{staticClass:"filter-count"},[_vm._v(" ("+_vm._s(_vm.filterCount)+") ")]):_vm._e()]),(_vm.filterCount > 0)?_c('button',{staticClass:"remove-filter",attrs:{"type":"button"},on:{"click":function($event){$event.stopPropagation();return _vm.removeFilters.apply(null, arguments)}}},[_c('img',{attrs:{"src":_vm.removeWhiteIcon,"alt":"remove"}})]):_vm._e()])])]),_c('div',{staticClass:"col-12 col-md-5 col-lg-6 row-inner row-right"},[(_vm.coursewareList?.count)?_c('div',{staticClass:"course-count"},[_vm._v(" "+_vm._s(_vm.$t("search_course.results_found", { count: _vm.coursewareList?.count }))+" ")]):_vm._e()])]),_c('div',{staticClass:"row course-row"},[(_vm.isFilterPanelOpen)?_c('div',{staticClass:"col-12 col-lg-3 left-panel"},[_c('div',{staticClass:"filter__list--container"},[_c('div',{staticClass:"filter__list--inner"},[(
                    !_vm.isDesktop && _vm.isFilterPanelOpen && _vm.coursewareList?.count
                  )?_c('div',{staticClass:"list-inner count"},[_vm._v(" "+_vm._s(_vm.coursewareList?.count)+" "+_vm._s(_vm.$t("course.results"))+" ")]):_vm._e(),_c('div',{staticClass:"list-inner"},[_c('div',{staticClass:"list-heading",on:{"click":function($event){return _vm.showFilterItems('pacing')}}},[_vm._v(" "+_vm._s(_vm.$t("course.filter.pacing"))+" "),(!_vm.isDesktop)?_c('span',{staticClass:"arrow"},[_c('img',{style:(_vm.isPacingItemsVisible
                            ? 'transform: rotate(180deg);'
                            : ''),attrs:{"src":_vm.arrowIcon,"alt":"arrow"}})]):_vm._e()]),(_vm.isPacingItemsVisible)?_c('div',{staticClass:"list"},_vm._l((_vm.filterTypes),function(item){return _c('div',{key:item.value,staticClass:"list-item"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedFilterType),expression:"selectedFilterType"}],attrs:{"type":"radio","id":item.value,"name":"course_types"},domProps:{"value":item.value,"checked":_vm.selectedFilterType === item.value,"checked":_vm._q(_vm.selectedFilterType,item.value)},on:{"click":_vm.setFilter,"change":function($event){_vm.selectedFilterType=item.value}}}),_c('label',{attrs:{"for":item.value}},[_vm._v(" "+_vm._s(item.label)+" ")])])}),0):_vm._e()]),_c('div',{staticClass:"list-inner"},[_c('div',{staticClass:"list-heading",on:{"click":function($event){return _vm.showFilterItems('language')}}},[_vm._v(" "+_vm._s(_vm.$t("my_profile.language.heading"))+" "),(!_vm.isDesktop)?_c('span',{staticClass:"arrow"},[_c('img',{style:(_vm.isLanguageItemsVisible
                            ? 'transform: rotate(180deg);'
                            : ''),attrs:{"src":_vm.arrowIcon,"alt":"arrow"}})]):_vm._e()]),(_vm.isLanguageItemsVisible)?_c('div',{staticClass:"list"},_vm._l((_vm.displayedLanguages),function(item){return _c('div',{key:item.id,staticClass:"list-item"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedLanguage),expression:"selectedLanguage"}],attrs:{"type":"radio","id":item.id,"name":"languages"},domProps:{"value":item.id,"checked":_vm.selectedLanguage === item.value,"checked":_vm._q(_vm.selectedLanguage,item.id)},on:{"click":_vm.setLanguage,"change":function($event){_vm.selectedLanguage=item.id}}}),_c('label',{attrs:{"for":item.id}},[_vm._v(" "+_vm._s(item.displayName)+" ")])])}),0):_vm._e(),(!_vm.showAll && _vm.isLanguageItemsVisible)?_c('button',{staticClass:"show-more",attrs:{"type":"button"},on:{"click":_vm.showMoreFilters}},[_vm._v(" "+_vm._s(_vm.$t("course.filter.show_more"))+" "),_c('img',{attrs:{"src":_vm.arrowBlueIcon,"alt":"arrow"}})]):_vm._e()])]),(_vm.$route.query.search && _vm.isDesktop)?_c('div',{staticClass:"filter__list--inner"},[_c('div',{staticClass:"list-inner"},[_c('div',{staticClass:"list-heading"},[_vm._v(" "+_vm._s(_vm.$t("course.top_courses"))+" ")]),_c('div',{staticClass:"list no-padding"},_vm._l((_vm.coursewareList?.semantic_results),function(course){return _c('div',{key:course.id,staticClass:"list-item top__courses--container"},[_c('div',{staticClass:"top__courses--inner",on:{"click":function($event){return _vm.onCardClick(course)}}},[_c('div',{staticClass:"course-image"},[_c('img',{attrs:{"src":course.image_url
                                ? course.image_url
                                : _vm.placeholderImage,"alt":"course"},on:{"error":_vm.handleImageError}})]),_c('div',{staticClass:"course-title"},[_vm._v(" "+_vm._s(course?.title)+" ")])])])}),0)])]):_vm._e(),(!_vm.isDesktop && _vm.isFilterPanelOpen)?_c('div',{staticClass:"done-btn"},[_c('LxpButton',{attrs:{"variant":"primary","type":"button"},on:{"click":_vm.hideFilterPanel}},[_vm._v(" "+_vm._s(_vm.$t("general.done"))+" ")])],1):_vm._e()])]):_vm._e(),_c('div',{class:['col-12', { 'col-lg-9 right-panel': _vm.isFilterPanelOpen }]},[(_vm.isCourseLoading)?_c('LxpLoader',{staticClass:"response-spinner"}):(
                _vm.coursewareList && _vm.coursewareList?.results?.length > 0
              )?_c('div',{class:[
                'course__list--container',
                { 'full-width': !_vm.isFilterPanelOpen }
              ]},_vm._l((_vm.coursewareList?.results),function(course){return _c('CourseCard',{key:course.id,attrs:{"course":course},on:{"cardClick":_vm.onCardClick}})}),1):(
                _vm.coursewareList && _vm.coursewareList?.results?.length === 0
              )?_c('EmptyState',{attrs:{"imageIcon":_vm.imageIcon,"title":_vm.$t('course.empty.title'),"content":_vm.$t('course.empty.content')}}):_vm._e()],1)]),(_vm.coursewareList && _vm.coursewareList?.results?.length > 0)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 d-flex justify-content-end"},[_c('b-pagination',{attrs:{"current-page":_vm.currentPage,"per-page":_vm.perPage,"first-number":"","last-number":"","total-rows":_vm.coursewareList?.count || 0},on:{"page-click":_vm.handlePaginationClick},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)]):_vm._e()]),(!this.isDesktop && _vm.isFilterPanelOpen)?_c('div',{staticClass:"filter-overlay",on:{"click":_vm.preventClick}},[_c('div',{staticClass:"close-btn",on:{"click":_vm.hideFilterPanel}},[_c('img',{attrs:{"src":_vm.removeIcon,"alt":"close"}})])]):_vm._e()]),_c('StayTuned')]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }